import React from 'react'
import Post from '@/components/Blog/Post'

class Feed extends React.Component {
  constructor(props) {
    super(props)
    let articlesCount = this.props.articles.length
    let initialCount = 6
    this.state = {
      count: articlesCount,
      initialLoad: initialCount,
      currentlyShowing: initialCount,
      nextFold: initialCount,
      showButton: articlesCount > initialCount,
    }

    this.handleLoadMore = this.handleLoadMore.bind(this)
  }

  handleLoadMore() {
    this.setState((prev) => {
      let newShowing = prev.currentlyShowing + prev.nextFold
      return {
        currentlyShowing: newShowing,
        showButton: prev.count > newShowing,
      }
    })
  }

  render() {
    const postsForRender = this.props.articles.slice(
      1,
      this.state.currentlyShowing,
    )
    return (
      <>
        <Post firstPost post={this.props.articles[0].node} />
        <div className='posts-feed'>
          {this.props.articles &&
            postsForRender.map(({ node: post }) => (
              <Post key={post.id} post={post}></Post>
            ))}
        </div>

        <div className='flex items-center justify-center mt-24 py-5'>
          {this.state.showButton ? (
            <button className='btn-primary' onClick={this.handleLoadMore}>
              Load more
            </button>
          ) : (
            ''
          )}
        </div>
      </>
    )
  }
}

export default Feed
