import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '@/components/Layout'
import Feed from '@/components/Blog/Feed'
import SEO from '@/helpers/SEOHelper.js'

const BlogPage = (data) => {
  const { edges: articles } = data.data.articles
  const pageData = data.data.markdownRemark
  const seo = pageData.frontmatter.seo

  return (
    <Layout>
      <Helmet>
        <meta name='description' content={SEO.description(seo.description)} />
        <meta property='og:title' content={SEO.title(seo.title)} />
        <meta
          property='og:description'
          content={SEO.description(seo.description)}
        />
        <meta property='og:image' content={SEO.image(seo.image)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>
      <div className='top-bg-blog top-bg  bg-grey-lightest' />
      <div className='container-lg pt-56 pb-24'>
        <h2 className='uppercase text-sm'>{pageData.frontmatter.hero.label}</h2>
        <h1 className='text-h3 sm:text-h1'>
          {pageData.frontmatter.hero.heading}
        </h1>
      </div>
      <div className='container-lg pb-24 md:pb-32 '>
        <Feed articles={articles} />
      </div>
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogPage

export const pageQuery = graphql`
  query BlogPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
      frontmatter {
        hero {
          heading
          label
        }
        seo {
          title
          description
        }
      }
    }
    articles: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "Article" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "DD/MM/YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 900, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
