import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import ReactSVG from 'react-svg'

const PostLink = ({ post, firstPost }) => {
  const postClass = firstPost ? 'post-item post-item--first' : 'post-item'
  return (
    <>
      <Link className={postClass} to={post.fields.slug}>
        <div className='post-item__image'>
          <Img
            fluid={post.frontmatter.featuredImage.childImageSharp.fluid}></Img>
        </div>
        <div className='post-item__content'>
          {!firstPost ? <div className='line' /> : ''}
          <div className='post-item__date'>{post.frontmatter.date}</div>
          <h2 className='post-item__title'>{post.frontmatter.title}</h2>
          {firstPost ? (
            <div className='post-item__excerpt'>{post.excerpt}</div>
          ) : (
            ''
          )}

          {firstPost ? (
            <div className='mt-5'>
              <button className='btn-dark'>Read more</button>
            </div>
          ) : (
            <div className='mt-5 flex items-center'>
              <span className='post-link mr-3 font-bold'>Read more </span>
              <ReactSVG src='/img/svg/arrow.svg' className='text-grey' />
            </div>
          )}
        </div>
      </Link>
    </>
  )
}
export default PostLink
